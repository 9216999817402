/*

        index.js

        Start it up :)

*/


var IndexApp = (function(){
  
    //  VARS
    var $this;
    var $body = document.body;
    var colors = ["#f56364", "#ffe852", "#20eacb"];
    var colorIndex = 0;
    var interval = 750
  
    return {
  

      init: function(){

        //  store ref to instance
        $this = this;

        var mailStr = "yes";
        mailStr += "@";
        mailStr += "you.have.agency";
        mailStr = "<a href='mailto:'" + mailStr + ">" + mailStr + "</a>"
        document.querySelector('.contact').innerHTML = mailStr;

        setInterval(function(){
            var newIndex = colorIndex++ % colors.length;
            var newColor = colors[newIndex];
            $body.style.background = newColor;
        }, interval );
        
      },

    };

  }());
  
  IndexApp.init();

  export { IndexApp };
